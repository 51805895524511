import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Header from '../../../components/Header';
import CMSView from '../../../components/View';
import TemplateEspace from '../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../utils/requireEspace';
import { UserProps } from '../../../utils/requireUser';

const DashboardPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Header
        description="Je te souhaite une bonne journée."
        title={`Bonjour ${user.firstName},`}
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(DashboardPage);
